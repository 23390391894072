import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { useParams } from '@tanstack/react-router'
import { memo, useCallback, useState } from 'react'
import { trpcReact } from '../../../../client'
import { NotFound } from '../../../organisms/not-found'
import { RenewalFailState } from '../../renewal/RenewalFailState'
import { RenewalStartState, type RcdaFields } from '../../renewal/RenewalStartState'
import { RenewalSuccessState } from '../../renewal/RenewalSuccessState'

type RenewalState =
  | { type: 'start' }
  | { type: 'success'; isLocked: boolean }
  | { type: 'failed' }
  | { type: 'no-renewal' }

const PlatformRenewalPage = memo(function PlatformRenewalPage() {
  const { endorsementId } = useParams({
    from: '/bak/contracts/$subscriptionId/renewal/$endorsementId',
  })

  const [renewalDetails] = trpcReact.renewal.getRenewalDetails.useSuspenseQuery({ renewalId: endorsementId })

  const validateRenewalAsOperator = trpcReact.renewal.validateRcdaRenewalAsOperator.useMutation()

  const [state, setState] = useState<RenewalState>({ type: 'start' })

  const goToSuccessState = () => {
    setState({ type: 'success', isLocked: renewalDetails.blockedStatus !== 'not-blocked' })
  }

  const goToFailState = () => {
    setState({ type: 'failed' })
  }

  const goToStartState = () => {
    setState({ type: 'start' })
  }

  const validateRenewal = useCallback(
    (rcdaFields: RcdaFields) => {
      validateRenewalAsOperator.mutate({
        renewalId: endorsementId,
        rcdaFields,
      })
    },
    [validateRenewalAsOperator, endorsementId],
  )

  return (
    <>
      <Header compact={true} />
      {state.type === 'start' ? (
        <RenewalStartState
          isRenewalValidationPending={validateRenewalAsOperator.isPending}
          renewalValidationType={
            validateRenewalAsOperator.isSuccess ? validateRenewalAsOperator.data.type : 'not-validated'
          }
          renewalDetails={renewalDetails}
          goToSuccessState={goToSuccessState}
          goToFailState={goToFailState}
          validateRenewal={validateRenewal}
        />
      ) : undefined}
      {state.type === 'success' ? (
        <RenewalSuccessState
          isLocked={state.isLocked}
          renewalDetails={renewalDetails}
          goToStartState={goToStartState}
          isBackoffice={true}
        />
      ) : undefined}
      {state.type === 'failed' ? <RenewalFailState /> : undefined}
      {state.type === 'no-renewal' ? <NotFound /> : undefined}
    </>
  )
})
export default PlatformRenewalPage
